import { Injector, NgModule } from '@angular/core';
import { OpenProjectPluginContext } from 'core-app/features/plugins/plugin-context';
import { ResourceDisplayField } from 'core-app/shared/components/fields/display/field-types/resource-display-field.module';
import { SelectEditFieldComponent } from 'core-app/shared/components/fields/edit/field-types/select-edit-field/select-edit-field.component';

export function initializeSpecializationsPlugin(injector: Injector) {
  window.OpenProject.getPluginContext().then((pluginContext: OpenProjectPluginContext) => {
    pluginContext.services.editField.addFieldType(SelectEditFieldComponent, 'select', ['Specialization']);
    pluginContext.services.displayField.addFieldType(ResourceDisplayField, 'resource', ['Specialization']);
  });
}

@NgModule({
  providers: [
  ],
})
export class PluginModule {
  constructor(injector: Injector) {
    initializeSpecializationsPlugin(injector);
  }
}
